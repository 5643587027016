
import { defineComponent } from "vue";

export default defineComponent({
  name: "Chart",
  props: {
    percent: { type: Number, default: 0 },
    size: { type: Number, default: 48 },
    text: { type: String, default: 0 }
  }
});
